// ============================================================================
// DocumentArchiveService
// -----------------------
// DocumentArchive module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/DocumentArchiveApi'
// ---------
// Internals
// ---------
const Private = {
	requests: {
		vendor: {
			documentArchive: {
				create: function (vendorId, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId })
					return API.vendor.documentArchive.create(vendorId, params, data)
				},
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			documentArchives: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	service: {
		vendor: {
			documentArchive: {
				create: function (vendorId, params = null, data = null) {
					RequiredArguments({ vendorId: vendorId })
					return Private.requests.vendor.documentArchive.create(vendorId, params, data)
				},
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			},
			documentArchives: {
				create: NotImplementedYet,
				read: NotImplementedYet,
				update: NotImplementedYet,
				delete: NotImplementedYet
			}
		}
	},
	store: {}
}
// -------
// Exports
// -------
export default {
	createDocumentArchive: Private.service.vendor.documentArchive.create,
	getDocumentArchive: Private.service.vendor.documentArchive.read,
	updateDocumentArchive: Private.service.vendor.documentArchive.update,
	deleteDocumentArchive: Private.service.vendor.documentArchive.delete,

	createDocumentArchives: Private.service.vendor.documentArchives.create,
	getDocumentArchives: Private.service.vendor.documentArchives.read,
	updateDocumentArchives: Private.service.vendor.documentArchives.update,
	deleteDocumentArchives: Private.service.vendor.documentArchives.delete
}
