// ============================================================================
// DocumentArchiveApi
// -------------------
// DocumentArchive api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
	vendor: {
		documentArchive: {
			create: function (vendorId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId: vendorId })
				return Backend.POST(URLS.api.vendors.archives.uri, [vendorId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
			},
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: NotImplementedYet
		},
		documentArchives: {
			create: NotImplementedYet,
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: NotImplementedYet
		}
	}
}
