<template>
	<div v-if="(!conditionalDisplay.isMobileApp && canDownload) || (conditionalDisplay.isMobileApp && model.length === 1 && model[0].is_document && canDownload)">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn :flat="flat" icon="get_app" :loading="isLoading" mini v-bind="$attrs" @click="download()"> {{ $t('ecm.btn_download') }}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="download()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">get_app</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'actions.download'" />
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'
import DocumentArchiveService from '@/services/Documents/DocumentArchiveService'

export default {
	name: 'DownloadButton',
	mixins: [DocumentActionModuleGuard],
	props: {
		version: {
			type: [Object, Array],
			required: false,
			default: null
		},
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		},
		flat: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: function () {
		return {
			isLoading: false
		}
	},
	computed: {
		model: {
			get: function () {
				let result = []
				if (this.value) {
					result = Array.isArray(this.value) ? this.value : [this.value]
				}
				return result
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		canDownload: function () {
			return this.model && this.model.length > 0 && this.model.every(node => !node.is_trash)
		}
	},
	methods: {
		download: function () {
			this.isLoading = true
			if (this.model.length === 1 && this.model[0].is_document) {
				const consideredVersion = this.version ? this.version : this.model[0].latestVersion
				const a = window.document.createElement('a')
				a.style = 'display:none'
				a.target = '_blank'
				const url = new URL(consideredVersion.uri)
				url.searchParams.set('download', true)
				a.href = url.href
				a.click()
				a.remove()
				this.eventBus.emit(this.events.DOCUMENT_DOWNLOADED, this.model[0])
				this.isLoading = false
			} else {
				const documents = []
				const folders = []
				this.model.forEach(node => {
					if (node.is_folder && !node.is_trash) {
						folders.push(node.id)
					} else if (node.is_document) {
						documents.push(node.id)
					}
				})
				DocumentArchiveService.createDocumentArchive(this.vendorId, null, {
					accounting_firm_id: this.$store.state.accountingFirm?.selected?.id,
					documents,
					folders
				})
					.then(archive => {
						if (archive.hasOwnProperty('message')) {
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('documents.archive.delayed', this.model.length))
						} else {
							let authParam = new URLSearchParams()
							authParam.set('token', this.$store.state.auth.token)

							let a = document.createElement('a')
							document.body.appendChild(a)
							a.style = 'display: none'
							a.href = archive.file + '?' + authParam.toString()
							a.target = '_blank'
							a.click()
							document.body.removeChild(a)
						}
					})
					.then(() => {
						this.model.forEach(node => {
							this.eventBus.emit(this.events.DOCUMENT_DOWNLOADED, node)
						})
					})
					.finally(() => {
						this.isLoading = false
					})
			}
		}
	}
}
</script>
